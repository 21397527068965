import { CodeCommand } from "commands/CodeCommand";
import { IdCommand } from "commands/IdCommand";
import { AddWatchWordCommand } from "commands/frankenstories/AddWatchWordCommand";
import { BanPlayerCommand } from "commands/frankenstories/BanPlayerCommand";
import { EditTeamCommand } from "commands/frankenstories/EditTeamCommand";
import { GameRoundCommand } from "commands/frankenstories/GameRoundCommand";
import { GameVotingRoundCommand } from "commands/frankenstories/GameVotingRoundCommand";
import { NameGameCommand } from "commands/frankenstories/NameGameCommand";
import { NotifyGameCommand } from "commands/frankenstories/NotifyGameCommand";
import { NotifyPlayerCommand } from "commands/frankenstories/NotifyPlayerCommand";
import { RejectReplyCommand } from "commands/frankenstories/RejectReplyCommand";
import { StartGameCommand } from "commands/frankenstories/StartGameCommand";
import { UpdateGameRulesSettingsCommand } from "commands/frankenstories/UpdateGameRulesSettingsCommand";
import { sessionId } from "contexts/GlobalContext";
import { GetEditTeamResult } from "results/frankenstories/GetEditTeamResult";
import { GetModerateResult } from "results/frankenstories/GetModerateResult";
import { GetPopularWatchWordsResult } from "results/frankenstories/GetPopularWatchWordsResult";
import { useApiService } from "services/ApiService";

export const ModerateApiService = () => {
    const { get, post } = useApiService({ controller: "/moderate" });

    return {
        rejectReply: (command: RejectReplyCommand) => post("/reject-reply", command),
        allowReply: (command: RejectReplyCommand) => post("/allow-reply", command),
        approveRound: (command: GameRoundCommand) => post("/approve-round", command),
        endWriting: (command: GameRoundCommand) => post("/end-writing", command),
        endVoting: (command: GameVotingRoundCommand) => post("/end-voting", command),
        endAwarding: (command: GameRoundCommand) => post("/end-awarding", command),
        notifyPlayer: (command: NotifyPlayerCommand) => post("/notify-player", command),
        notifyGame: (command: NotifyGameCommand) => post("/notify-game", command),
        getModerate: (code: string) => get<GetModerateResult>(`/moderate/${code}`),
        startGame: (command: StartGameCommand) => post("/start", command),
        nominateGameForHallOfFame: (command: CodeCommand) =>
            post("/nominate-game-for-hall-of-fame", { ...command, sessionId }),
        updateRules: (command: UpdateGameRulesSettingsCommand) => post("/update-rules", command),
        banPlayer: (command: BanPlayerCommand) => post("/ban-player", command),
        unbanPlayer: (command: BanPlayerCommand) => post("/unban-player", command),
        deleteGame: (code: string) => post("/delete", { code }),
        endGame: (command: CodeCommand) => post("/end", command),
        nameGame: (command: NameGameCommand) => post("/name", command),
        getPopularWatchWords: () => get<GetPopularWatchWordsResult>("/popular-watch-words"),
        pauseGame: (command: CodeCommand) => post("/pause-game", command),
        resumeGame: (command: CodeCommand) => post("/resume-game", command),
        awardSticker: (command: IdCommand) => post("/award-sticker", command),
        removeSticker: (command: IdCommand) => post("/remove-sticker", command),
        addPlayerToWatchList: (command: IdCommand) => post("/add-player-to-watch-list", command),
        removePlayerFromWatchList: (command: IdCommand) => post("/remove-player-from-watch-list", command),
        addWatchWord: (command: AddWatchWordCommand) => post("/add-watch-word", command),
        removeWatchWord: (command: IdCommand) => post("/remove-watch-word", command),
        getEditTeam: (id: number) => get<GetEditTeamResult>(`/edit-team/${id}`),
        editTeam: (command: EditTeamCommand) => post("/edit-team", command),
    };
};
